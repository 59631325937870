<template>
  <a v-if="isExternal(item.url)" :href="item.url" :target="item.target">
    {{ item.text }}
  </a>
  <nuxt-link v-else :to="item.url">
    {{ item.text }}
  </nuxt-link>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isExternal(url) {
      if (url.startsWith('/files')) {
        return true;
      }

      return url.startsWith('http');
    },
  },
};
</script>
