<template>
  <div class="flex flex-col min-h-screen overflow-x-hidden">
    <toolbar/>
    <main class="flex-grow">
      <nuxt/>
      <blocks/>
    </main>
    <app-footer/>
  </div>
</template>

<script>

import Toolbar from './partials/Toolbar';
import AppFooter from './partials/Footer';
import Blocks from '~/layouts/partials/Blocks';

export default {
  components: {Toolbar, AppFooter, Blocks},
};
</script>
