<template>
  <footer class="container flex flex-wrap py-10">
    <section class="w-full sm:w-1/3 lg:w-1/4 pr-4 mb-4">
      <header class="h3 pb-6">Over Groene Norm</header>
      <p>
        {{ settings.tekst }}
      </p>
    </section>

    <nav v-for="group in footerMenu" :key="group.text" class="w-full sm:w-1/3 lg:w-1/4 pr-4 mb-4">
      <header class="h3 pb-6">{{ group.text }}</header>
      <ul class="list-disc pl-4">
        <li v-for="child in group.children" :key="child.url" class="mb-3">
          <nuxt-link :to="child.url" class="text-primary">
            {{ child.text }}
          </nuxt-link>
        </li>
      </ul>
    </nav>

    <address class="w-full lg:w-1/4 mb-4 not-italic">
      <header class="h3 pb-6">Contact</header>
      <div class="flex mb-4">
        <font-awesome-icon :icon="['far', 'map-marker-alt']" class="mr-4"/>
        <div>{{ settings.address }}, {{ settings.postalcode }},<br> {{ settings.city }}</div>
      </div>
      <a :href="`tel:${settings.phone}`" class="text-primary mb-4 block">
        <font-awesome-icon :icon="['far', 'phone']" class="mr-2"/>
        {{ settings.phone }}
      </a>
      <a :href="`mailto:${settings.email}`" class="text-primary block">
        <font-awesome-icon :icon="['far', 'envelope']" class="mr-2"/>
        {{ settings.email }}
      </a>
    </address>

    <section class="flex flex-wrap w-full justify-between mt-10">
      <div class="w-full text-center md:text-left md:w-auto">
        <span class="mr-2">© {{ new Date().getFullYear() }} Groene Norm</span>
        <span v-for="link in legalMenu" :key="link.url">|<nuxt-link :to="link.url" class="text-secondary mx-2">{{ link.text }}</nuxt-link></span>
      </div>
    </section>
  </footer>
</template>

<script>
import {mapState} from 'vuex';

export default {
  computed: {
    ...mapState({
      settings: state => state.settings,
      footerMenu: state => state.footerMenu,
      legalMenu: state => state.legalMenu,
    }),
  },
};
</script>
