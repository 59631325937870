<template>
  <div>
    <section v-for="block in blocks" :key="block.id">
      <faq-block v-if="block.title === 'Veelgestelde vragen'" :text="block.text"/>
    </section>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  components: {
    FaqBlock: () => import('~/components/fullwidth-blocks/FaqBlock'),
  },
  computed: {
    ...mapState({
      blocks: state => (state.page.template && state.page.template.blokken) || [],
    }),
  },
};
</script>
