<template>
  <div>
    <div
      :style="`transform: translateY(${scrolled ? -36 : 0}px)`"
      class="navbar fixed w-full z-40 duration-200 ease-in-out"
    >
      <!-- Top menu-->
      <section class="h-10 bg-primary-light"/>
      <section class="h-24 lg:h-32 bg-white shadow-toolbar">
        <nav class="h-full flex container items-center text-primary justify-between">
          <!-- Logo -->
          <nuxt-link to="/" :class="scrolled ? 'h-20' : 'h-16'" class="flex duration-200 ease-in-out">
            <img src="~/assets/img/icon.jpg" alt="Groene Norm" class="object-contain object-left lg:object-center">
          </nuxt-link>
          <!-- Main menu -->
          <ul class="hidden lg:flex main-menu h-full items-center relative">
            <li
              v-for="item in mainMenu"
              :key="item.text"
              class="menu-item flex h-full items-center relative px-5 last:pr-0"
              @mouseover="activeChild = item.text"
              @mouseleave="activeChild = null"
            >
              <nuxt-link :to="item.url">{{ item.text }}</nuxt-link>

              <!-- 2nd level -->
              <template v-if="item.children">
                <ul
                  v-show="activeChild === item.text"
                  class="main-menu--2nd-level"
                >
                  <li
                    v-for="child in item.children"
                    :key="child.text"
                    @mouseleave="activeGrandChild1 = null"
                    @mouseover="activeGrandChild1 = child.text"
                  >
                    <nuxt-link v-if="child.children" :to="child.url" class="flex items-center justify-between">
                      {{ child.text }}
                      <font-awesome-icon :icon="['fas', 'chevron-right']" size="sm"/>
                    </nuxt-link>

                    <menu-item v-else :item="child"/>

                    <!-- 3rd level -->
                    <template v-if="child.children">
                      <ul
                        v-show="activeGrandChild1 === child.text"
                        class="main-menu--3rd-level"
                      >
                        <li
                          v-for="grandchild2 in child.children"
                          :key="grandchild2.url"
                          @mouseleave="activeGrandChild2 = null"
                          @mouseover="activeGrandChild2 = grandchild2.text"
                        >
                          <nuxt-link
                            v-if="grandchild2.children"
                            :to="grandchild2.url"
                            class="flex items-center justify-between"
                          >
                            {{ grandchild2.text }}
                            <font-awesome-icon :icon="['fas', 'chevron-right']" size="sm"/>
                          </nuxt-link>

                          <menu-item v-else :item="grandchild2"/>

                          <!-- 4th level -->
                          <template v-if="grandchild2.children">
                            <ul
                              v-show="activeGrandChild2 === grandchild2.text"
                              class="main-menu--4th-level"
                            >
                              <li
                                v-for="grandchild3 in grandchild2.children"
                                :key="grandchild3.name"
                                @mouseleave="activeGrandChild3 = null"
                                @mouseover="activeGrandChild3 = grandchild3.text"
                              >
                                <nuxt-link
                                  v-if="grandchild3.children"
                                  :to="grandchild3.url"
                                  class="flex items-center justify-between"
                                >
                                  <span class="w-5/6">
                                    {{ grandchild3.text }}
                                  </span>
                                  <font-awesome-icon :icon="['fas', 'chevron-right']" size="sm"/>
                                </nuxt-link>

                                <menu-item v-else :item="grandchild3"/>

                                <!-- 5th level -->
                                <template v-if="grandchild2.children">
                                  <ul
                                    v-show="activeGrandChild3 === grandchild3.text"
                                    class="main-menu--5th-level"
                                  >
                                    <li
                                      v-for="grandchild4 in grandchild3.children"
                                      :key="grandchild4.name"
                                    >
                                      <menu-item :item="grandchild4"/>
                                    </li>
                                  </ul>
                                </template>
                              </li>
                            </ul>
                          </template>
                        </li>
                      </ul>
                    </template>
                  </li>
                </ul>
              </template>
            </li>
            <li class="menu-item flex h-full items-center relative px-5 last:pr-0">
              <button aria-label="Zoeken" @click="toggleSearch">
                <font-awesome-icon :icon="['fas', 'search']"/>
              </button>
            </li>
          </ul>

          <div class="lg:hidden main-menu h-full items-center relative flex flex-row">
            <button aria-label="Toon of sluit menu" class="mr-4" @click="toggleMenu">
              <font-awesome-icon :icon="['fas', 'bars']" size="lg"/>
            </button>
            <button aria-label="Zoeken" @click="toggleSearch">
              <font-awesome-icon :icon="['fas', 'search']" size="lg"/>
            </button>
          </div>
        </nav>
      </section>
    </div>

    <!-- Mobile menu -->
    <transition name="slide">
      <ul v-if="menuOpen" class="mobile-menu z-50 fixed left-0 right-0 bottom-0 h-screen bg-white">
        <li
          v-for="item in mainMenu"
          :key="item.text"
          class="items-center relative px-5 flex flex-wrap justify-between w-full"
        >
          <nuxt-link active-class="text-secondary" :to="item.url">{{ item.text }}</nuxt-link>
          <font-awesome-icon
            v-if="item.children"
            :icon="activeChild === item.text ? ['fas', 'chevron-up'] : ['fas', 'chevron-down']"
            size="sm"
            @click="toggleActiveChild(item.text)"
          />

          <!-- 2nd level -->
          <template v-if="item.children">
            <ul v-show="activeChild === item.text" class="w-full">
              <li
                v-for="child in item.children"
                :key="child.text"
                class="pl-5 pt-3"
              >
                <div class="flex flex-wrap w-full justify-between">
                  <nuxt-link active-class="text-secondary w-full" :to="child.url">{{ child.text }}</nuxt-link>
                  <font-awesome-icon
                    v-if="child.children"
                    :icon="activeGrandChild1 === child.text ? ['fas', 'chevron-up'] : ['fas', 'chevron-down']"
                    size="sm"
                    @click="toggleActiveGrandChild1(child.text)"
                  />
                </div>
                <!-- 3rd level -->
                <template v-if="child.children">
                  <ul v-show="activeGrandChild1 === child.text">
                    <li
                      v-for="grandChild2 in child.children"
                      :key="grandChild2.text"
                      class="w-full pl-5 pt-3"
                    >
                      <div class="flex flex-wrap w-full justify-between">
                        <nuxt-link active-class="text-secondary w-full" :to="grandChild2.url">
                          {{ grandChild2.text }}
                        </nuxt-link>
                        <font-awesome-icon
                          v-if="grandChild2.children"
                          :icon="activeGrandChild2 === grandChild2.text ? ['fas', 'chevron-up'] : ['fas', 'chevron-down']"
                          size="sm"
                          @click="toggleActiveGrandChild2(grandChild2.text)"
                        />
                      </div>
                      <!-- 4th level -->
                      <template v-if="grandChild2.children">
                        <ul v-show="activeGrandChild2 === grandChild2.text">
                          <li
                            v-for="grandChild3 in grandChild2.children"
                            :key="grandChild3.text"
                            class="w-full pl-5 pt-3"
                          >
                            <div class="flex flex-wrap w-full justify-between">
                              <nuxt-link active-class="text-secondary w-full" :to="grandChild3.url">
                                {{ grandChild3.text }}
                              </nuxt-link>
                              <font-awesome-icon
                                v-if="grandChild3.children"
                                :icon="activeGrandChild3 === grandChild3.text ? ['fas', 'chevron-up'] : ['fas', 'chevron-down']"
                                size="sm"
                                @click="toggleActiveGrandChild3(grandChild3.text)"
                              />
                            </div>
                            <!-- 5th level -->
                            <template v-if="grandChild3.children">
                              <ul v-show="activeGrandChild3 === grandChild3.text">
                                <li
                                  v-for="grandChild4 in grandChild3.children"
                                  :key="grandChild4.text"
                                  class="w-full pl-5 pt-3"
                                >
                                  <div class="flex flex-wrap w-full justify-between">
                                    <nuxt-link active-class="text-secondary w-full" :to="grandChild4.url">
                                      {{ grandChild4.text }}
                                    </nuxt-link>
                                    <font-awesome-icon
                                      v-if="grandChild4.children"
                                      :icon="activeGrandChild4 === grandChild4.text ? ['fas', 'chevron-up'] : ['fas', 'chevron-down']"
                                      size="sm"
                                    />
                                  </div>
                                </li>
                              </ul>
                            </template>
                          </li>
                        </ul>
                      </template>
                    </li>
                  </ul>
                </template>
              </li>
            </ul>
          </template>
        </li>
        <li class="hidden lg:visible menu-item flex h-full items-center relative px-5 last:pr-0">
          <button aria-label="Zoeken">
            <font-awesome-icon :icon="['fas', 'search']"/>
          </button>
        </li>
      </ul>
    </transition>

    <!-- Search modal-->
    <transition name="slide">
      <div v-if="searchOpen" class="search-modal bg-secondary">
        <div class="container flex flex-wrap justify-center h-full">
          <div class="w-full flex justify-end text-white mt-5">
            <button aria-label="Zoeken" @click="toggleSearch">
              <font-awesome-icon :icon="['fas', 'times']" size="2x" aria-label="Zoeken"/>
            </button>
          </div>
          <div class="h-screen w-3/4 search-field flex items-center justify-center">
            <label class="relative">
              <input
                ref="searchInput"
                v-model="q"
                type="text"
                placeholder="Typ hier en druk op enter..."
                autofocus
                class="w-full"
                @keyup.enter="sendClose"
                @keyup.esc="toggleSearch"
              >
            </label>
          </div>
        </div>
      </div>
    </transition>

    <div class="navbar-placeholder"/>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import MenuItem from './MenuItem';

export default {
  components: {MenuItem},
  data() {
    return {
      activeChild: null,
      activeGrandChild1: null,
      activeGrandChild2: null,
      activeGrandChild3: null,
      activeGrandChild4: null,
      scrolled: false,
      menuOpen: false,
      searchOpen: false,
      q: null,
    };
  },
  computed: {
    ...mapState({
      settings: state => state.settings,
      mainMenu: state => state.mainMenu,
    }),
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.menuOpen = false;
      },
    },
  },
  mounted() {
    this.scrolled = window.scrollY > 50;
    window.addEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      this.scrolled = window.scrollY > 50;
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    toggleSearch() {
      this.searchOpen = !this.searchOpen;

      if (this.searchOpen) {
        this.$nextTick(function () {
          this.$refs.searchInput.focus();
        });
      }
    },
    toggleActiveChild(text) {
      if (text === this.activeChild) {
        this.activeChild = null;
      } else {
        this.activeChild = text;
      }
    },
    toggleActiveGrandChild1(text) {
      if (text === this.activeGrandChild1) {
        this.activeGrandChild1 = null;
      } else {
        this.activeGrandChild1 = text;
      }
    },
    toggleActiveGrandChild2(text) {
      if (text === this.activeGrandChild2) {
        this.activeGrandChild2 = null;
      } else {
        this.activeGrandChild2 = text;
      }
    },
    toggleActiveGrandChild3(text) {
      if (text === this.activeGrandChild3) {
        this.activeGrandChild3 = null;
      } else {
        this.activeGrandChild3 = text;
      }
    },
    sendClose() {
      if (!this.q) {
        return;
      }

      this.submit();
      this.search = !this.search;
    },
    submit() {
      this.$router.push({path: '/zoek-resultaten', query: {q: this.q}});
      this.searchOpen = false;
      this.q = '';
    },
  },
};
</script>

<style scoped>
.main-menu {
  ul {
    @apply absolute shadow-md;

    li {
      @apply relative block px-5 py-2 w-64 bg-white text-primary;

      &:hover, &:focus {
        @apply bg-secondary-dark text-white;
      }
    }

    ul {
      z-index: 1;
      top: 8px;
    }
  }

  &--2nd-level {
    top: 100%;
    left: 0;
  }

  &--3rd-level, &--5th-level {
    left: calc(-100% + 8px);

    @screen xl {
      left: calc(100% - 8px);
    }
  }

  &--4th-level {
    left: calc(100% - 8px);

    @screen xl {
      left: calc(-100% + 8px);
    }
  }
}

.top-menu-item, .menu-item:not(.main-menu--2nd-level) {
  &:hover {
    @apply text-secondary transition duration-300;
  }
}

.menu-item {
  > a {
    @apply relative;

    &::after {
      @apply absolute block left-0 right-0 bg-secondary opacity-0 transition duration-300;
      content: '';
      bottom: -8px;
      height: 2px;
    }

    &:active {
      @apply text-secondary;
    }
  }

  &:hover {
    @apply text-secondary transition duration-300;

    > a:after {
      @apply bg-secondary opacity-100;
      bottom: -2px;
    }
  }
}

.navbar-placeholder {
  height: 136px;
  @screen lg {
    height: 168px;
  }
}

.login-button {
  @apply absolute;
  left: 19px;
  @screen md {
    @apply relative;
  }
}

.search-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 120;

  .search-field {
    label {
      @apply text-white text-xl;
    }

    input {
      background: none;
      width: 260px;

      &:focus {
        @apply outline-none;
      }

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: white;
        opacity: .7;
      }
    }
  }
}

.mobile-menu {
  top: 136px;

  > li {
    @apply w-full;
    padding: .7rem 1rem;
  }
}

/* Transition */
.slide-enter-active {
  transition-duration: .3s;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
  max-height: 100vh;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
