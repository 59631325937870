export const state = () => ({
  release: process.env.RELEASE,
  page: {},
  mainMenu: [],
  footerMenu: [],
  legalMenu: [],
  settings: {},
  questions: [],
  highlightedQuestions: [],
});

export const actions = {
  async nuxtServerInit({commit}) {
    const [menus, settings, questions] = await Promise.all([
      this.$axios.get('/menus/combined'),
      this.$axios.get('/settings'),
      this.$axios.get('/questions'),
    ]);

    commit('SET_MENUS', menus.data);
    commit('SET_SETTINGS', settings.data);
    commit('SET_QUESTIONS', questions.data.data);
    commit('SET_HIGHLIGHTED_QUESTIONS', questions.data.data);
  },
  async getPage({commit}, slug = '') {
    const {data} = await this.$axios.get(`/page?segments=${slug}`);

    commit('SET_PAGE', data.data);
  },
};

export const mutations = {
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_MENUS(state, payload) {
    state.mainMenu = payload.main;
    state.footerMenu = payload.footer;
    state.legalMenu = payload.legal;
  },
  SET_SETTINGS(state, payload) {
    state.settings = payload;
  },
  SET_QUESTIONS(state, payload) {
    state.questions = payload;
  },
  SET_HIGHLIGHTED_QUESTIONS(state, payload) {
    state.highlightedQuestions = payload.filter(question => question.highlighted);
  },
};
