import Vue from 'vue';
import {library, config} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

import {
  faSearch,
  faSignInAlt,
  faCircle,
  faBars,
  faTimes,
  faChevronRight as faChevronRightSolid,
  faChevronDown as faChevronDownSolid,
  faChevronUp as faChevronUpSolid,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faTwitter,
  faFacebook,
} from '@fortawesome/free-brands-svg-icons';

import {
  faClock,
  faFolderOpen,
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/pro-regular-svg-icons';

config.autoAddCss = false;

library.add(
  faChevronRight,
  faChevronLeft,
  faTwitter,
  faFacebook,
  faSearch,
  faSignInAlt,
  faClock,
  faFolderOpen,
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
  faChevronDown,
  faChevronUp,
  faCircle,
  faBars,
  faTimes,
  faChevronRightSolid,
  faChevronDownSolid,
  faChevronUpSolid,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
