<template>
  <div class="flex flex-col">
    <div class="container flex flex-wrap">
      <main class="content-styling w-full lg:w-3/4 lg:pl-12 pb-12 not-italic">
        <h1 v-if="error.statusCode === 410 || error.statusCode === 404">{{ page.title }}</h1>
        <h1 v-else>Er is een fout opgetreden</h1>
        <div class="page-content" v-html="page.tekst"/>
        <nuxt-link to="/" class="mt-8">
          Terug naar de Homepage
        </nuxt-link>
      </main>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  props: {
    error: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: mapState(['page']),
  async created() {
    await this.$store.dispatch('getPage', '404');
  },
  head() {
    return {
      title: this.page.meta ? this.page.meta.title : this.page.title,
      meta: [
        this.page.meta && this.page.meta.description
          ? {
            hid: 'description',
            name: 'description',
            content: this.page.meta.description,
          }
          : {},
      ],
    };
  },
};
</script>
