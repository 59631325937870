import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _293831e8 = () => interopDefault(import('../pages/nieuws/index.vue' /* webpackChunkName: "pages/nieuws/index" */))
const _388e9419 = () => interopDefault(import('../pages/zoek-resultaten.vue' /* webpackChunkName: "pages/zoek-resultaten" */))
const _5cf18ee1 = () => interopDefault(import('../pages/groene-norm/team-groene-norm.vue' /* webpackChunkName: "pages/groene-norm/team-groene-norm" */))
const _2ca57478 = () => interopDefault(import('../pages/nieuws/_slug.vue' /* webpackChunkName: "pages/nieuws/_slug" */))
const _5923de80 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _47381718 = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/nieuws",
    component: _293831e8,
    name: "nieuws"
  }, {
    path: "/zoek-resultaten",
    component: _388e9419,
    name: "zoek-resultaten"
  }, {
    path: "/groene-norm/team-groene-norm",
    component: _5cf18ee1,
    name: "groene-norm-team-groene-norm"
  }, {
    path: "/nieuws/:slug",
    component: _2ca57478,
    name: "nieuws-slug"
  }, {
    path: "/",
    component: _5923de80,
    name: "index"
  }, {
    path: "/*",
    component: _47381718,
    name: "*"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
