<template>
  <div>
    <form v-if="!success" method="post" @submit.prevent="submit">
      <div v-for="field in template.fields" :key="field.name">
        <div v-if="field.type !== 'checkbox'">
          <span>{{ label(field) }}</span>
          <span v-if="errors[field.name]" style="color: red;">{{ errors[field.name][0] }}</span>
        </div>
        <textarea
          v-if="field.type === 'textarea'"
          v-model="form[field.name]"
          :aria-label="field.label"
          rows="5"
        />
        <select
          v-else-if="field.type === 'select'"
          v-model="form[field.name]"
          :aria-label="field.label"
          class="w-full"
        >
          <option v-for="option in field.options" :key="option">{{ option }}</option>
        </select>
        <div v-else-if="field.type === 'checkbox'">
          <label class="checkbox">
            <input
              v-model="form[field.name]"
              :type="field.type"
              value="1"
            >
            <span>{{ field.label }}</span>
          </label>
          <div v-if="errors[field.name]" style="color: red;">{{ errors[field.name][0] }}</div>
        </div>
        <input
          v-else
          v-model="form[field.name]"
          :type="field.type"
          :aria-label="field.label"
        >
      </div>
      <button :disabled="loading" @click="submit">
        Verstuur
      </button>
    </form>
    <transition name="fade">
      <div v-if="popup" class="fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-black-light">
        <div
          class="flex flex-col justify-center items-center relative bg-white w-80 rounded-lg shadow-2xl p-8 pt-3"
          role="alert"
        >
          <h3 class="text-lg font-bold">Het bericht is verstuurd!</h3>
          <p class="-mt-4">We zullen spoedig contact opnemen</p>
          <button class="bg-secondary hover:bg-secondary-dark text-white rounded transition duration-200 py-3 px-8" @click="popup = false">
            Oke
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import commonForm from '~/plugins/mixins/form';

export default {
  mixins: [commonForm],
  props: {
    id: {
      type: Number,
      default: 1,
    },
    html: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      success: false,
      template: {},
      form: {},
      loading: false,
      popup: false,
    };
  },
  async mounted() {
    const fields = await this.$axios.get('/forms/' + this.id);

    this.template = fields.data.data;

    // Set defaults
    const that = this;
    this.template.fields.forEach((field) => {
      if (field.type === 'checkbox') {
        // Default must be array to work
        that.form[field.name] = [];
      }
    });
  },
  methods: {
    async submit() {
      this.loading = true;
      try {
        await this.$axios.post('/forms/' + this.id, {...this.form});
        this.form = {};
        this.success = true;
        this.$emit('success');
        this.popup = true;
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
      this.success = false;
    },
    label(field) {
      if (field.required) {
        return `${field.label} *`;
      }

      return field.label;
    },
  },
};
</script>

<style lang="css">
.fade-enter-active, .fade-leave-active {
  transition: opacity .8s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
